<template>
  <v-container>
    <v-row class="text-center">
      <v-col
          class="mb-5"
          cols="12"
      >
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            Pozzyvibes Reviews
          </h1>
        </v-col>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row dense>
            <v-col
                v-for="review in reviews"
                :key="review.review"
                :cols="auto"
            >
              <v-card
                  :loading="loading"
                  class="mx-auto my-12"
                  max-width="400"
              >
                <v-img
                    :src="review.src"
                    class="white--text align-end"
                    max-height="400"
                    max-width="400"
                >
                </v-img>

                <v-card-text>
                  <div v-html=review.review></div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="text-center">
    <v-col class="mb-4">
      <h3 class="display-1 font-weight-bold mb-1">
        Leave us a review on <a href="https://search.google.com/local/writereview?placeid=ChIJUatV5ijZ5ogRQ80upFVHkzM&source=g.page.m.mp&laa=merchant-panel-review-solicitation-promoted-task">Google</a>, <a href="https://www.weddingwire.com/reviews/pozzyvibes-denver/4029d84ccaaa476b.html">WeddingWire</a>, <a href="https://www.facebook.com/Pozzyvibes/">Facebook</a>, or <a href="https://pozzyvibes.com/reviews.html#">The Knot!</a>
      </h3>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PozzyReviews',
  data: () => ({
    reviews: [
      {
        review: 'My husband and I hired Pozzyvibes to DJ our wedding reception. I highly recommend Pozzyvibes for any DJ services you need! They did a wonderful job keeping the party alive and played great music! Pozzyvibes has their customers fill out detail oriented paper work to make sure they fit every need possible to make your night perfect. They are so easy to work with and fulfilled many special requests that we had! Thanks Pozzyvibes! - Karly Mcfarland',
        src: require("../assets/pozzylogo.png")
      },
      {
        review: 'The owner Tiffany was very professional and a pleasure to work with.  We hired DJ Pozzyvibes for out annual adult dances at the Port Orange Adult Center for a 50\'s music themed dance.  She had a great playlist ready, and was willing to take requests to ensure that our guests had a great experience.  She had a great equipment set up and great lighting that really added something special.  Thank you Tiffany! - Port Orange Adult Center',
        src: require("../assets/pozzylogo.png")
      },
      {
        review: 'She did an amazing job at my wedding! - Josh and Chelsea',
        src: require("../assets/pozzylogo.png")
      },
      {
        review: 'Wonderful dj. Tiffany made the day when our wedding was running late she kept our guests entertained. Couldn\'t ask for a better person! - Jesse',
        src: require("../assets/pozzylogo.png")
      },
      {
        review: 'We were so lucky to have them as our DJ for our special day! Had an awesome playlist and had something to play for everyone on our guest list !!!! Well recommended. Even had a friend use them for their wedding !! - Monica',
            src: require("../assets/pozzylogo.png")
      },
      {
        review: 'I loved having Tiffany dj for our wedding! She cared about what we wanted, and she made sure she knew what we wanted by calling and asking. She gave me lists of popular songs for each wedding event, and she even made sure she had a song for me that I wanted to walk down the aisle to, that wasn’t very popular. The music selection was on key! She did a wonderful job interacting our guests! She added a slide show of our pictures too on her monitor in the front. -Veronica',
            src: require("../assets/pozzylogo.png")
      },
      {
        review: 'I have worked with Tiffany and Pozzyvibes on several events and they are Fantastic! They are a very fun, energetic, and friendly group to work with! Their sound equipment is excellent and the music mix for our events is always perfect. I highly recommend Pozzyvibes for all your DJ services! - Katie',
            src: require("../assets/pozzylogo.png")
      },
    ],
  }),
}
</script>
