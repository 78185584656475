<template>
  <div class="reviews">
    <PozzyReviews/>
  </div>
</template>

<script>
// @ is an alias to /src
import PozzyReviews from '@/components/Reviews.vue'

export default {
  name: 'Reviews',
  components: {
    PozzyReviews
  }
}
</script>
